.Play-logo {
  height: 58px;
  width: 'auto';
}

.Appstore-logo {
  height: 40px;
  width: 'auto';
}

.Albin-logo {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Albin-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}